<template>
  <main>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="auth-header">
            <buttonLink :destination="'/'" :vegangLogo="true"> </buttonLink>
            <button-link
              :isInternal="true"
              v-if="loggedIn && userType == 'retailer'"
              :content="' Go To dropshipper Dashboard'"
              :destination="'/retailer'"
              :filled="true"
            >
            </button-link>
            <button-link
              :isInternal="true"
              v-if="!loggedIn"
              :content="'Login as dropshipper'"
              :destination="'/login/retailer'"
              :filled="true"
            >
            </button-link>
          </div>
        </div>
      </div>
    </div>
    <div class="auth-page for-supplier">
      <div class="authpage-header">
        <h1 class="title for-supplier">
          <span class="green-text">Hi, </span>Welcome back
          <span class="sub for-supplier"
            >Login to supplier dashboard to manage inventory.</span
          >
        </h1>
      </div>

      <div class="auth-box">
        <h2 class="form-title">Go to Your Dashboard</h2>
        <div class="errorMessage-container" v-if="showAuthError">
          <div class="material-icons error-icon">cancel</div>
          <div>
            Ops! something happened
            <span>{{ errorMessage }}</span>
          </div>
        </div>
        <div class="form-body">
          <div class="form">
            <CustomInput
              type="email"
              id="email"
              icon="email"
              label="Email address"
              v-model="login.email"
              :required="true"
            />
            <CustomPasswordInput
              type="password"
              id="password"
              icon="key"
              label="Password"
              v-model="login.password"
            />
            <button class="vgang-button form-button" @click="handleLogin">
              Login
            </button>
            <span class="forgot-pass">Forgot Password?</span>
          </div>
        </div>
        <div class="box-footer">
          <span class="footer-text"
            >Do you want to be a supplier?
            <buttonLink
              :content="'Become a Supplier'"
              :destination="'/register/supplier'"
              :greenText="true"
            ></buttonLink>
          </span>
        </div>
      </div>
      <img
        src="@/assets/img/supplier--bg.svg"
        loading="lazy"
        alt="auth"
        class="auth-shape"
      />
    </div>
  </main>
</template>

<script>
import CustomInput from "@/components/CustomInput.vue";
import CustomPasswordInput from "@/components/CustomPasswordInput.vue";
import buttonLink from "@/components/bases/ButtonLink.vue";
// Services
import { uuid } from "vue-uuid";
import CommonData from "@/services/CommonData";
import { setVariable, getVariable } from "@/utils/conditionalStoreVariables";
export default {
  metaInfo: {
    title: "vgang|login-supplier",
    meta: [
      {
        name: "description",
        content: "Login to supplier dashboard to manage inventory",
      },
    ],
  },
  data() {
    return {
      userType: "",
      showAuthError: false,
      errorMessage: "",
      uuid: uuid.v1(),
      login: {
        email: "",
        password: "",
        DeviceId: this.$uuid.v4(),
        userType: "supplier",
        service: "vgang",
      },
    };
  },
  components: {
    CustomInput,
    CustomPasswordInput,
    buttonLink,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.checkRole();
    }
  },
  methods: {
    checkRole() {
      const user = getVariable("user");
      if (user) {
        CommonData.getCurrentUser()
          .then(function (response) {
            this.userType = response.data.role;
            setVariable("role", this.userType);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        return;
      }
    },
    handleLogin() {
      this.message = "";
      this.submitted = true;

      setVariable("role", "supplier");
      this.$store.dispatch("auth/login", this.login).then(
        (data) => {
          this.$router.push({ name: "Supplier" });

          this.successful = true;
          this.showAuthError = false;
        },
        (error) => {
          this.successful = false;
          this.showAuthError = true;
          this.errorMessage = error.response.data.message;
        }
      );
    },
  },
};
</script>

<style></style>
